import React from "react";
import "../../index.scss";
import "./contact.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    yourName: "",
    companyName: "",
    emailAdress: "",
    textArea: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const sendEmail = () => {
    const { yourName, companyName, emailAdress, textArea } = formData;
    const emailBody = `${textArea}%0D%0A%0D%0AMed Vänliga Hälsningar / Best Regards%0D%0A${yourName}%0D%0A${emailAdress}`;
    window.open(
      `mailto:johnson@pixagon.se?subject=Nytt meddelande från ${yourName} @ ${companyName}&body=${emailBody}`
    );
  };
  return (
    <div className="component contactComponent">
      <div className="componentMain contactMain">
        <label htmlFor="contactForm" className="formLabel htmlTag">
          {t("inTouch")}
        </label>
        <form
          className="contactForm htmlTag"
          method="get"
          onSubmit={(e) => {
            e.preventDefault();
            sendEmail();
          }}
        >
          <label htmlFor="yourName">{t("yourName")}</label>
          <input
            type="text"
            className="yourName z2"
            placeholder="..."
            id="yourName"
            name="user_name"
            onChange={handleInputChange}
          />

          <label htmlFor="companyName">{t("companyName")}</label>
          <input
            type="text"
            className="companyName z2"
            placeholder="..."
            id="companyName"
            name="company_name"
            onChange={handleInputChange}
          />

          <label htmlFor="emailAdress">{t("emailAdress")}</label>
          <input
            type="email"
            className="emailAdress z2"
            placeholder="..."
            id="emailAdress"
            name="user_email"
            onChange={handleInputChange}
          />

          <label htmlFor="textArea" className="textAreaLabel">
            {t("message")}
          </label>
          <textarea
            name="textArea"
            id="textArea"
            className="z2"
            placeholder="..."
            onChange={handleInputChange}
          ></textarea>

          <input type="submit" className="formSubmit z2" value={t("submit")} />
        </form>

        <div className="contactBox">
          <i className="fa fa-envelope fa-lg"></i>
          <p>contact@pixagon.se</p>
        </div>
        <div className="contactBox">
          <i className="fa fa-phone fa-lg"></i>
          <p>(+46)70 343 12 99</p>
        </div>
      </div>
      <div className="alertBox">
        <p>
          {t("alertMsg1")}
          <br />
          {t("alertMsg2")}
        </p>
      </div>
      <span className="componentTitle">{t("contact")}</span>
    </div>
  );
};

export default Contact;
