import React from "react";
import "../../index.scss";
import "./portfolio.scss";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();
  return (
    <div className="component portfolioComponent">
      <div className="portfolioMain componentMain">
        {/* Seled */}
        <div className="projectDescription">
          <h3 className="projectTitle">Swedish Environment Led Lighting AB</h3>
          <p className="projectText">{t("seledDesc")}</p>
          <p className="projectText">{t("seledText")}</p>
        </div>
        <div className="projectImages">
          <a href="https://www.seled.se" target="_blank" rel="noreferrer">
            <img
              src={require("../../media/projectsImages/seled/seled1920.webp")}
              alt="Seled full screen size"
              className="projectImgBig"
            />
            <img
              src={require("../../media/projectsImages/seled/seledTablet.webp")}
              alt="Seled tablet size"
              className="projectImgTablet"
            />
            <img
              src={require("../../media/projectsImages/seled/seledPhone.webp")}
              alt="Seled phone size"
              className="projectImgPhone"
            />
          </a>
        </div>
        {/* Tenantia */}
        <div className="projectDescription">
          <h3 className="projectTitle">Tenantia AB</h3>
          <p className="projectText">{t("tenantiaDesc")}</p>
          <p className="projectText">{t("tenantiaText")}</p>
        </div>
        <div className="projectImages">
          <a href="https://www.tenantia.se" target="_blank" rel="noreferrer">
            <img
              src={require("../../media/projectsImages/tenantia/tenantia1920.webp")}
              alt="Tenantia full screen size"
              className="projectImgBig"
            />
            <img
              src={require("../../media/projectsImages/tenantia/tenantiaTablet.webp")}
              alt="Tenantia tablet size"
              className="projectImgTablet"
            />
            <img
              src={require("../../media/projectsImages/tenantia/tenantiaPhone.webp")}
              alt="Tenantia phone size"
              className="projectImgPhone"
            />
          </a>
        </div>
        {/* Happydeli */}
        <div className="projectDescription">
          <h3 className="projectTitle">Happydeli AB</h3>
          <p className="projectText">{t("happydeliDesc")}</p>
          <p className="projectText">{t("happydeliText")}</p>
        </div>
        <div className="projectImages">
          <a href="https://www.deliday.se" target="_blank" rel="noreferrer">
            <img
              src={require("../../media/projectsImages/happydeli/happydeli1920.webp")}
              alt="Happydeli full screen size"
              className="projectImgBig"
            />
            <img
              src={require("../../media/projectsImages/happydeli/happydeliTablet.webp")}
              alt="Happydeli tablet size"
              className="projectImgTablet"
            />
            <img
              src={require("../../media/projectsImages/happydeli/happydeliPhone.webp")}
              alt="Happydeli phone size"
              className="projectImgPhone"
            />
          </a>
        </div>
      </div>
      <span className="componentTitle">{t("portfolio")}</span>
    </div>
  );
};

export default Portfolio;
