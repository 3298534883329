import React from "react";
import "../../index.scss";
import "./service.scss";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();
  return (
    <div className="component serviceComponent">
      <div className="serviceMain componentMain">
        <div className="serviceIconLeft">
          <div className="serviceIconInner">
            <i className="fa fa-laptop-code faIcon"></i>
          </div>
        </div>
        <div className="serviceTextRight">
          <h3 className="tileTitle htmlTag">{t("webDevelopmentTitle")}</h3>
          <p className="tileText htmlTag">{t("webDevelopmentText")}</p>
        </div>
        <div className="serviceIconRight">
          <div className="serviceIconInner">
            <i className="fa fa-clock faIcon"></i>
          </div>
        </div>
        <div className="serviceTextLeft">
          <h3 className="tileTitle htmlTag">{t("supportTitle")}</h3>
          <p className="tileText htmlTag">{t("supportText")}</p>
        </div>
        <div className="serviceIconLeft">
          <div className="serviceIconInner">
            <i className="fa fa-mobile faIcon"></i>
          </div>
        </div>
        <div className="serviceTextRight">
          <h3 className="tileTitle htmlTag">{t("designTitle")}</h3>
          <p className="tileText htmlTag">{t("designText")}</p>
        </div>
        <div className="serviceIconRight">
          <div className="serviceIconInner">
            <i className="fa fa-search faIcon"></i>
          </div>
        </div>
        <div className="serviceTextLeft">
          <h3 className="tileTitle htmlTag">{t("seoTitle")}</h3>
          <p className="tileText htmlTag">{t("seoText")}</p>
        </div>
        <div className="serviceIconLeft">
          <div className="serviceIconInner">
            <i className="fa fa-hashtag faIcon"></i>
          </div>
        </div>
        <div className="serviceTextRight">
          <h3 className="tileTitle htmlTag">{t("socialsTitle")}</h3>
          <p className="tileText htmlTag">{t("socialsText")}</p>
        </div>
      </div>
      <span className="componentTitle">{t("service")}</span>
    </div>
  );
};

export default Service;
