import React from "react";
import "../../index.scss";
import "./about.scss";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="component aboutComponent">
      <div className="aboutMain componentMain">
        <img
          src={require("../../media/Pixagon Logo Small.png")}
          alt="Adam Johnson"
          className="aboutImage"
        ></img>
        <div className="aboutText">
          <h2 className="aboutTitle">{t("aboutTitle")}</h2>
          <p className="aboutDescription">{t("aboutDescription1")}</p>
          <p className="aboutDescription">{t("aboutDescription2")}</p>
        </div>
      </div>
      <span className="componentTitle">{t("about")}</span>
    </div>
  );
};

export default About;
