import React from "react";
import "../../index.scss";
import "./footer.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <p>© 2023, Pixagon</p>
    </footer>
  );
};

export default Footer;
