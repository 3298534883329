import React, { useState } from "react";
import "../../index.scss";
import "./splash.scss";
import { useTranslation } from "react-i18next";

const Splash = () => {
  const { t } = useTranslation();

  return (
    <div className="component splashComponent">
      <div className="splashMain componentMain">
        <p>
          Pixag
          <svg
            className="svgHex"
            width="200"
            height="200"
            viewBox="0 0 200 200"
          >
            <title id="hexagonTitle">Hexagon Shape</title>
            <desc id="hexagonDesc">
              A hexagon with lines intersecting at various points, logo of
              Pixagon.
            </desc>
            <polygon
              id="hexagon"
              points="46.5,100 81.5,50 128.5,50 163.5,100 128.5,150 81.5,150"
              fill="none"
              stroke="#cc7000"
              strokeWidth="5"
            />
            <line
              id="line1"
              x1="81.5"
              y1="50"
              x2="81.5"
              y2="150"
              stroke="#cc7000"
              strokeWidth="5"
            />
            <line
              id="line2"
              x1="81.5"
              y1="50"
              x2="128.5"
              y2="150"
              stroke="#cc7000"
              strokeWidth="5"
            />
            <line
              id="line3"
              x1="46.5"
              y1="100"
              x2="128.5"
              y2="50"
              stroke="#cc7000"
              strokeWidth="5"
            />
          </svg>
          n
        </p>
      </div>
    </div>
  );
};

export default Splash;
