import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import "./i18n/config";
import { useTranslation } from "react-i18next";

// Components
import Nav from "./components/nav/nav";
import Splash from "./components/splash/splash";
import About from "./components/about/about";
import Service from "./components/service/service";
import Portfolio from "./components/portfolio/portfolio";
import References from "./components/references/references";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Cookies from "./components/cookies/cookies";

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <BrowserRouter>
        <Cookies />
        <Nav />
        <div id="splash">
          <Splash />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="service">
          <Service id="service" />
        </div>
        <div id="portfolio">
          <Portfolio id="portfolio" />
        </div>
        {/*
        <div id="references">
          <References id="references" />
        </div>
  */}
        <div id="contact">
          <Contact id="contact" />
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

/*

let language;
let languageIcon;
let cookie = document.cookie.split(";");
let cookiesAllowed;

// Initial set the language on load of new page
function setLanguage(lang) {
  fetchJson();

  console.log("setLanguage", lang);

  async function fetchJson() {
    const res = await fetch("src/json/" + lang + ".json");
    fetch("src/json/" + lang + ".json")
      .then((res) => res.json())
      .catch((error) => console.error(error));

    let jsonData = await res.json();
    let langArray = jsonData;

    console.log("langArray", langArray);

    for (var i in langArray) {
      let className = document.getElementsByClassName(i);
      for (var y in className) {
        className[y].textContent = langArray[i];
        console.log(y);
      }
    }

    if (cookie[0].split("=").pop() === "true") {
    setLanguageCookie();
  }
  }
}



// Set Language Cookie
function setLanguageCookie() {
  document.cookie =
    "language=" +
    language +
    "; expires=" +
    new Date(Date.now() + 86400 * 365000).toUTCString();
}

// Check if cookies is already allowed
cookie.forEach((element) => {
  if (element.split("=")[0].trim() === "allowCookies") {
    if (element.split("=").pop() === "true") {
      document.getElementsByClassName("cookieBox")[0].style.display = "none";
    }
  }
});

// Set Allow Cookies
function setAllowCookiesCookie(isTrue) {
  if (isTrue === true) {
    document.cookie =
      "allowCookies=true; expires=" +
      new Date(Date.now() + 86400 * 365000).toUTCString();
  } else {
    console.log(
      "Cookies disabled. If you wish to allow cookies, please reload the page"
    );
  }
  document.getElementsByClassName("cookieBox")[0].style.display = "none";
}


// Change language
if (cookie[1] != null) {
  language = cookie[1].split("=").pop();
} else {
  language = navigator.language || navigator.userLanguage;
}

// Set the language
setLanguage(language.split("-").shift());
 */
