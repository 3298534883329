import React from "react";
import "../../index.scss";
import "./nav.scss";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div>
      <div className="bigNavBg">
        <ul className="bigNav">
          <li onClick={() => scrollTo("about")}>{t("about")}</li>
          <li onClick={() => scrollTo("service")}>{t("service")}</li>
          <li onClick={() => scrollTo("portfolio")}>{t("portfolio")}</li>
          <li onClick={() => scrollTo("contact")}>{t("contact")}</li>
        </ul>

        <div
          className="mobileNavButton"
          onClick={() => {
            toggleSidebar();
          }}
        >
          <i className="fa fa-bars"></i>
        </div>
        <div className="mobileNav" id="mNav">
          <ul className="mobileNavList" id="mnList">
            <li onClick={() => scrollTo("splash")}>{t("home")}</li>
            <li onClick={() => scrollTo("about")}>{t("about")}</li>
            <li onClick={() => scrollTo("service")}>{t("service")}</li>
            <li onClick={() => scrollTo("portfolio")}>{t("portfolio")}</li>
            <li onClick={() => scrollTo("contact")}>{t("contact")}</li>
          </ul>
        </div>
      </div>
      <div className="leftFoot">
        <p className="mailAdress">contact@pixagon.se</p>
      </div>
      <div className="rightFoot">
        <div className="languageContainer">
          <ul className="languageList">
            <li>
              <i className="fa fa-globe languageIconSmall"></i>
            </li>
            <li>
              <div
                className="svFlagImg"
                onClick={() => changeLanguage("sv")}
                role="button"
                tabIndex="0"
              />
            </li>
            <li>
              <div
                className="enFlagImg"
                onClick={() => changeLanguage("en")}
                role="button"
                tabIndex="0"
              />
            </li>
            <li>
              <i className="fa fa-globe languageIconBig"></i>
            </li>
          </ul>
        </div>
        <ul className="socialsList">
          <li>
            <i className="fa fa-linkedin" />
          </li>
          <li>
            <i className="fa fa-github" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Nav;

function scrollTo(component) {
  document
    .getElementById(component)
    .scrollIntoView({ block: "end", behavior: "smooth" });
}

function toggleSidebar() {
  let mobileNav = document.getElementById("mNav");
  let mobileNavList = document.getElementById("mnList");
  let leftFoot = document.getElementsByClassName("leftFoot");
  let rightFoot = document.getElementsByClassName("rightFoot");

  if (mobileNav.style.width > "0px") {
    mobileNav.style.width = "0";
    mobileNavList.style.display = "none";
    leftFoot[0].style.display = "none";
    rightFoot[0].style.display = "none";
  } else {
    mobileNav.style.width = "40rem";
    mobileNavList.style.display = "block";
    leftFoot[0].style.display = "block";
    rightFoot[0].style.display = "block";
  }
}
