import React, { useEffect } from "react";
import "../../index.scss";
import "./cookies.scss";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem("cookiesAccepted")) {
      document.getElementById("cookieBanner").style.display = "block";
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    document.getElementById("cookieBanner").style.display = "none";
  };

  return (
    <div id="cookieBanner" className="cookieBanner">
      <p>{t("cookies")}</p>
      <button onClick={acceptCookies}>{t("accept")}</button>
    </div>
  );
};

export default Cookies;
